import { useImageSlider } from 'fitify-ui'
import { ThemeVariants } from 'fitify-ui-landing/src/theme/index'
import { useCallback, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { ELEMENT_IDS } from '../../../@types/constants'

import { HeaderProps } from './Header.Types'

export const useHeader = ({
  headerImages,
}: Pick<HeaderProps, 'headerImages'>) => {
  const { currentImageIndex, setImageSliderInterval } =
    useImageSlider(headerImages)
  const theme = useTheme()
  const [isImageSliderMounted, setIsImageSliderMounted] = useState(false)
  const currentImage = headerImages[currentImageIndex]

  // Prevent initial fade animation on first render
  useEffect(() => {
    setIsImageSliderMounted(true)
  }, [])

  const handleScrollToAnimation = useCallback(() => {
    const element = document.getElementById(ELEMENT_IDS.mobileAnimation)
    const navigation = document.getElementById(ELEMENT_IDS.navigation)

    if (element && navigation) {
      // Prevents the image slider from changing while scrolling
      setImageSliderInterval()

      const navigationHeight = navigation.clientHeight
      window.scrollTo({
        top: element.offsetTop - navigationHeight,
        left: 0,
      })
    }
  }, [setImageSliderInterval])

  return {
    currentImage,
    currentImageIndex,
    handleScrollToAnimation,
    isDigital: theme.variant === ThemeVariants.DIGITAL,
    isImageSliderMounted,
  }
}
