import { useCallback, useEffect, useRef, useState } from 'react'

// Custom hook for creating an image slider
function useImageSlider(images: any[], intervalTime = 6000) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const setImageSliderInterval = useCallback(() => {
    const imagesCount = images.length

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(() => {
      setCurrentImageIndex((prevCount) => (prevCount + 1) % imagesCount)
    }, intervalTime)
  }, [images, intervalTime])

  useEffect(() => {
    setImageSliderInterval()

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [setImageSliderInterval])

  return { currentImageIndex, setImageSliderInterval }
}

export default useImageSlider
